import { Suspense, lazy } from 'react';
import { AuthGuard } from './components/authentication/auth-guard';
import ACL from './components/authentication/ACL';
import LoadingScreen from './components/loadingScreen';
import { DashboardLayout } from './components/layout/layout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const HomePage = Loadable(lazy(() => import('./pages/homePage.index')));
const Import = Loadable(lazy(() => import('./pages/import.index')));
const Tools = Loadable(lazy(() => import('./pages/tools.index')));
const Gdpr = Loadable(lazy(() => import('./pages/gdpr.index')));
const UserList = Loadable(lazy(() => import('./pages/user.index')));
const TtsPostTravaux = Loadable(lazy(() => import('./pages/ttsPostTravaux.index')));
const ListeCuau = Loadable(lazy(() => import('./pages/cuau.index')));
const BpAncien = Loadable(lazy(() => import('./pages/bp.index')));

const PrestaChoix = Loadable(lazy(() => import('./pages/prestaChoix/index')));
const PrepaBackOffice2 = Loadable(lazy(() => import('./pages/prepaBackOffice/index2')));
const PrepaBackOffice3 = Loadable(lazy(() => import('./pages/prepaBackOffice/index3')));
const ReviewAgentMoar = Loadable(lazy(() => import('./pages/reviewAgentMoar/index')));
const ReviewAgentAre = Loadable(lazy(() => import('./pages/reviewAgentAre/index')));
const ReviewMarcheMoar = Loadable(lazy(() => import('./pages/reviewMarcheMoar/index')));
const ListeSelfcare = Loadable(lazy(() => import('./pages/liste/selfcare.index')));
const ListeD0D5 = Loadable(lazy(() => import('./pages/liste/d0d5.index')));
const ListeBp = Loadable(lazy(() => import('./pages/liste/bp.index')));
const ListePreprog = Loadable(lazy(() => import('./pages/liste/preprog.index')));
const ListeBonuMalus = Loadable(lazy(() => import('./pages/liste/bonusMalus.index')));
const ListeD0SansD2 = Loadable(lazy(() => import('./pages/liste/d0SansD2.index')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/error/401')));
const NotFound = Loadable(lazy(() => import('./pages/error/404')));
const ServerError = Loadable(lazy(() => import('./pages/error/500')));


const routes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <HomePage />
      },
      {
        path: 'outils',
        element: <Tools />
      },
      {
        path: 'imports',
        element: <Import />
      },
      {
        path: 'gdpr',
        element: <Gdpr />
      },
      {
        path: 'selfcare',
        element: <ListeSelfcare />
      },
      {
        path: 'd0d5',
        element: <ListeD0D5 />
      },
      {
        path: 'preprog',
        element: <ListePreprog />
      },
      {
        path: 'bonusMalus',
        element: <ListeBonuMalus />
      },
      {
        path: 'd0SansD2',
        element: <ListeD0SansD2 />
      },
      {
        path: 'cuau',
        element: <ListeCuau />
      },
      {
        path: 'ttsPostTravaux',
        element: <TtsPostTravaux />
      },
      {
        path: 'prestataire',
        element: <PrestaChoix />
      },
      {
        path: 'prepaBackOffice',
        element: <PrepaBackOffice2 />
      },
      {
        path: 'bp',
        element: <PrepaBackOffice3 />
      },
      {
        path: 'bp/liste',
        element: <ListeBp />
      },
      {
        path: 'bp/ancien',
        element: (
          <BpAncien />
        )
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            element: (
              <ACL allowedRoles={['RESPONSABLE']} fallback={<AuthorizationRequired />}>
                <UserList />
              </ACL>
            )
          },
          {
            path: 'revueAgentARE',
            element: (
              <ACL allowedRoles={['RESPONSABLE']} allowedNni={['G10438']} fallback={<AuthorizationRequired />}>
                <ReviewAgentAre />
              </ACL>
            )
          },
          {
            path: 'revueAgentMOAR',
            element: (
              <ACL allowedRoles={['RESPONSABLE']} fallback={<AuthorizationRequired />}>
                <ReviewAgentMoar />
              </ACL>
            )
          },
          {
            path: 'marcheMOAR',
            element: (
              <ACL allowedRoles={['API']} fallback={<AuthorizationRequired />}>
                <ReviewMarcheMoar />
              </ACL>
            )
          },
        ]
      },
    ]
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
