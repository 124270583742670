import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import TableViewIcon from '@mui/icons-material/TableView';
import CalculateIcon from '@mui/icons-material/Calculate';
import UpdateIcon from '@mui/icons-material/Update';
import PersonSearchIcon from '@mui/icons-material/WorkspacesOutlined';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import {
  Box, Grid,
  Button, Divider,
  Drawer, FormControl, InputLabel,
  IconButton, InputAdornment, OutlinedInput, Typography,
  useMediaQuery
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Logo } from '../logo';
import { Scrollbar } from '../scrollbar';
import { DashboardSidebarSection } from './sidebarMenuSection';
import { moment, addBusinessDays } from '../../utils/date';
import { useSessionStorage } from 'react-use';

export const DashboardSidebar = (props) => {

  const [devAlertIsAcknowledge, setDevAlertIsAcknowledge] = useSessionStorage('devAlertIsAcknowledge',
    !window.location.href.includes('web-'));

  const { onClose, open } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('xl'), { noSsr: true });
  const location = useLocation()

  const handlePathChange = () => {
    if (open) {
      onClose?.();
    }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  // travaux 
  const [openCalculator, setOpenCalculator] = useState(false);
  const travauxOsrRef = useRef();
  // const travauxDate = useRef();
  const [travauxDate, setTravauxDate] = useState(null);
  const [travauxOsrResult, setTravauxOsrResult] = useState(null);

  const handleClickOpenCalculator = () => {
    setOpenCalculator(true);
  };
  const handleClose = () => {
    setOpenCalculator(false);
  };

  const handleTravauxSearch = async () => {
    try {
      let response = await axios.get('/api/osr/dateD3', { params: { refAffaire: travauxOsrRef.current?.value } });

      const raw = response?.data?.data
      // console.log('updated ', raw, moment(raw?.updatedAt), moment(raw?.updatedAt).fromNow())
      if (!raw.dateAccordClient) {
        setTravauxOsrResult("Pas de D3 dans l'application pour ce dossier, dernière mise à jour " + moment(raw?.updatedAt)?.fromNow())
      } else {

        const dateAccordClient = moment(raw?.dateAccordClient, 'YYYY-MM-DD')
        const dateMAx = moment(dateAccordClient).add(90, 'days')
        // console.log({
        //   raw: response?.data?.data?.dateAccordClient,
        //   dateAccordClient: dateAccordClient.format("YYYY-MM-DD")
        //   , dateMAx: dateMAx.format("YYYY-MM-DD")
        // });

        setTravauxOsrResult(`D3 le ${dateAccordClient.format('DD/MM/YYYY')}, donc travaux à réaliser avant le : ${dateMAx.format('DD/MM/YYYY')}`)
      }

    } catch (error) {
      setTravauxOsrResult("Impossible de calculer la date.")
      console.error(error);
    }

  };



  const sections = [
    {
      title: 'Développeur',
      allowedRoles: ['DEV'],
      items: [
        {
          title: 'Pré-prog',
          allowedRoles: ['DEV'],
          path: '/preprog',
          icon: <TipsAndUpdatesIcon fontSize="small" />,
          // sx: { color: '#f5f6f7' }
          sx: { color: 'yellow' },
          colors: { text: 'orange' }
        },
        {
          title: 'Bonus Malus',
          allowedRoles: ['DEV'],
          path: '/bonusMalus',
          icon: <TipsAndUpdatesIcon fontSize="small" />,
          colors: { text: 'blue' }
          // sx: { color: '#e3e5e9' }
        },
      ],
      sx: { color: 'orange' }
    },
    {
      title: 'ARE',
      allowedRoles: ['DEFAULT'],
      items: [
        {
          title: 'Prépa Back-Office',
          allowedRoles: ['DEFAULT'],
          path: '/prepaBackOffice',
          icon: <TableViewIcon fontSize="small" />
        },
        {
          title: 'Branchement Provisoire',
          allowedRoles: ['DEV'],
          sx: { color: 'yellow' },
          path: '/bp',
          icon: <TableViewIcon fontSize="small" />
        },
        {
          title: 'Branchement Provisoire - Liste',
          allowedRoles: ['DEV'],
          sx: { color: 'yellow' },
          path: '/bp/liste',
          icon: <TableViewIcon fontSize="small" />
        },
        {
          title: 'Revue agent',
          allowedRoles: ['RESPONSABLE'],
          path: '/user/revueAgentARE',
          icon: <QueryStatsIcon fontSize="small" />
        },
        {
          title: 'CUAU',
          allowedRoles: ['DEFAULT'],
          path: '/cuau',
          icon: <RouteOutlinedIcon fontSize="small" />
        },
        {
          title: 'Auto-revue devis',
          allowedRoles: ['DEFAULT'],
          path: '/d0SansD2',
          icon: <TipsAndUpdatesIcon fontSize="small" />,
          // sx: { color: '#f5f6f7' }
          sx: { color: 'yellow' },
          colors: { text: 'orange' }
        },
        {
          title: 'Appel post travaux',
          allowedRoles: ['DEFAULT'],
          sx: { color: 'green' },
          path: '/ttsPostTravaux',
          icon: <RouteOutlinedIcon fontSize="small" />
        },
      ]
    },
    {
      title: 'MOAR',
      allowedRoles: ['DEFAULT'],
      items: [
        {
          title: 'Revue agent',
          allowedRoles: ['RESPONSABLE'],
          path: '/user/revueAgentMOAR',
          icon: <QueryStatsIcon fontSize="small" />
        },
        {
          title: 'Conso marché',
          allowedRoles: ['DEFAULT'],
          // path: '/user/marcheMOARNew',
          path: '/user/marcheMOAR',
          icon: <QueryStatsIcon fontSize="small" />
        },
        {
          title: 'Bonus Malus',
          allowedRoles: ['DEV'],
          path: '/bonusMalus',
          icon: <TipsAndUpdatesIcon fontSize="small" />,
          // colors: { text: 'blue' }
          // sx: { color: '#e3e5e9' }
        },
        {
          title: 'Choisir un prestataire',
          allowedRoles: ['DEFAULT'],
          path: '/prestataire',
          icon: <MapsUgcOutlinedIcon fontSize="small" />
        },
        {
          title: 'Selfcare',
          allowedRoles: ['DEFAULT'],
          path: '/selfcare',
          icon: <RouteOutlinedIcon fontSize="small" />
        },
        {
          title: 'BP ancien',
          allowedRoles: ['DEV'],
          path: '/bp/ancien',
          icon: <RouteOutlinedIcon fontSize="small" />
        },
        {
          title: 'Calculette dates',
          allowedRoles: ['DEFAULT'],
          onClick: handleClickOpenCalculator,
          icon: <CalculateIcon fontSize="small" />
        },
        {
          title: 'D0-D5 > 150J',
          allowedRoles: ['DEFAULT'],
          path: '/d0d5',
          icon: <QueryStatsIcon fontSize="small" />
        },
      ]
    },
    {
      title: 'Gestion',
      allowedRoles: ['DEFAULT'],
      items: [
        {
          title: 'Utilisateurs',
          allowedRoles: ['RESPONSABLE'],
          path: '/user',
          icon: <PersonSearchIcon fontSize="small" />
        },
        // {
        //   title: 'Etat des robots',
        //   allowedRoles: ['DEFAULT'],
        //   path: '/imports',
        //   icon: <VisibilityIcon fontSize="small" />
        // },
      ]
    },
  ];

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{
            p: 3,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
            {/* logo */}
            <RouterLink to={'/'}>
              <Logo
                sx={{
                  height: 42,
                  width: 42
                }}
              />
            </RouterLink>
            {/* name */}
            <Typography variant="h5" ml={1} >
              Portail-ARMGP {window.location.href.includes('web-') && '(DEV-v22)'}
            </Typography>
          </Box>

          {/* menu */}
          <Divider
            sx={{
              borderColor: '#2D3748',  // dark divider
              my: 3
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            {sections.map((section) => (
              <DashboardSidebarSection
                key={section.title}
                path={location.pathname}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2
                  },
                  ...section?.sx
                }}
                {...section} />
            ))}
          </Box>

          <Divider sx={{ borderColor: '#2D3748',  /* dark divider */ }} />

          {/* new dialog */}
          <Dialog
            open={openCalculator}
            onClose={handleClose}
            maxWidth='xl'
            PaperProps={{
              sx: {
                width: "100%",
                'align-items': "center"
              },
            }}
          >
            <DialogContent>
              <Box sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper' }}>
                <Grid container spacing={2}>
                  {/* first part */}
                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h5" component="div">
                      Calcul de la date limite de réalisation de travaux client à partir de la D3
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      <Grid container alignItems="center">
                        <Grid item>
                          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor={'osr'}>
                              Ref OSR
                            </InputLabel>
                            <OutlinedInput
                              inputRef={travauxOsrRef}
                              type="text"
                              id="osr"
                              label='refOSR'
                              fullWidth
                              onChange={() => {
                                if (travauxOsrRef?.current?.value.length === 8) { handleTravauxSearch() }
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Rechercher"
                                    onClick={handleTravauxSearch}
                                    edge="end"
                                  >
                                    <UpdateIcon color="inherit" />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          OU
                        </Grid>
                        <Grid item>
                          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <DatePicker
                              id='date'
                              value={travauxDate}
                              renderInput={(params) => <TextField {...params} fullWidth />}
                              onChange={(newValue) => {
                                const dateAccordClient = moment(newValue, 'DD/MM/YYYY')
                                const dateMAx = moment(dateAccordClient).add(90, 'days')
                                setTravauxOsrResult(`D3 le ${dateAccordClient.format('DD/MM/YYYY')}, donc travaux à réaliser avant le : ${dateMAx.format('DD/MM/YYYY')}`)
                                setTravauxDate(newValue)
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Typography>
                    Résultat: <br /> {travauxOsrResult}
                  </Grid>

                  {/* second part */}
                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h5" component="div">
                      Calcul JL
                    </Typography>
                    <Typography gutterBottom variant="body1">
                      <br />
                      Dans 15j ouvrés nous serons le: {addBusinessDays(moment(), 15).format('DD/MM/YYYY')} (branchement)  <br />
                      Dans 25j ouvrés nous serons le: {addBusinessDays(moment(), 25).format('DD/MM/YYYY')} (branchement + terrassement)  <br />
                      Dans 45j ouvrés nous serons le: {addBusinessDays(moment(), 45).format('DD/MM/YYYY')} (délai maxi travaux presta)
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Dialog>

          {/* old */}

          {/* assistance */}
          <Box sx={{ p: 2 }}>
            <Typography
              color="neutral.100"
              variant="subtitle2"
            >
            </Typography>
            <Button
              color="secondary"
              component="a"
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
              href="https://teams.microsoft.com/l/chat/0/0?users=mathieu-m.fernandes%40enedis.fr"
            >
              {'Un bug ? Contactez moi'}
            </Button>
          </Box>
        </Box>
      </Scrollbar >


    </>
  );


  // wide or narrow window
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (<>
    {/* dialog that alert we are in dev */}
    <Dialog
      open={!devAlertIsAcknowledge}
    // onClose={() => setDevAlertIsAcknowledge(true)}
    >
      <DialogContent>
        <Box sx={{ width: 400 }}>
          {'Vous êtes sur la version de développement de l\'application.'}
          <Button
            color="secondary"
            component="a"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"

            href={window.location.href.replace('web-', '')}
          >
            {'Retour à l\'application normale'}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDevAlertIsAcknowledge(true)}>Masquer ce message</Button>
      </DialogActions>
    </Dialog>

    {/* menu */}
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  </>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
