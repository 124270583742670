import {
  Button, Typography, AlertTitle, Snackbar,
  Slide,
  Alert
} from '@mui/material';
import { useAuth } from '../hooks/use-auth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { moment } from '../utils/date';

export const UserInformationSnackbar = (props) => {
  const { user } = useAuth();
  const [displaySnackBar, setDisplaySnackBar] = useState(true);
  const [displayGdpr, setDisplayGdpr] = useState(true);

  useEffect(() => {
    // Restore state from local/session storage
    if (user.snackbarDismiss === true) {
      setDisplaySnackBar(false);
    }
    if (user.gdprDismiss === true) {
      setDisplayGdpr(false);
    }
  },
    [user]);

  const handleDismissSnackbar = async () => {
    if (displaySnackBar) {
      setDisplaySnackBar(false);
      try {
        await axios.post('/api/user/dismiss-snackbar');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDismissGdpr = async () => {
    if (displayGdpr) {
      setDisplayGdpr(false);
      try {
        await axios.post('/api/user/dismiss-gdpr');
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {
        displayGdpr &&
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={displayGdpr}
          TransitionComponent={(TransitionProps) => (<Slide {...TransitionProps} direction="up" />)}
        >
          <Alert severity="info" sx={{ width: "100%" }}>
            <Typography variant="h4" gutterBottom>
              Règlement Général de Protection des Données
              <br />et protection des Informations Commercialement Sensibles
            </Typography>
            <Typography variant="body1" paragraph>
              Vous devez respecter les règles suivantes :
              <br />La non divulgation d'informations à des tiers non autorisés (notamment à travers les fichiers d'export)
              <br />La saisie d'informations objectives, nécessaire et suffisantes au traitement
              <br />Les informations saisies doivent être licites, adéquates, pertinentes et non excessives au regard du contexte.
              <br />Elles ne doivent comporter aucune appréciation sur le comportement ou les traits de caractère et aucun jugement de valeur, ni faire apparaître, directement ou indirectement, des informations relatives aux origines raciales ou ethniques, opinions politiques, philosophiques ou religieuses, à l’appartenance syndicale, à la santé, à la vie sexuelle ainsi qu’aux sanctions et condamnations de toute personne.
            </Typography>

            <Button
              color="primary"
              size="small"
              onClick={handleDismissGdpr}
            >
              Ne plus afficher ce message
            </Button>
          </Alert >
        </Snackbar >
      }
      {
        displaySnackBar && <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={displaySnackBar}
          TransitionComponent={(TransitionProps) => (<Slide {...TransitionProps} direction="up" />)}
        >
          <Alert severity="warning" sx={{ width: "100%" }}>
            <AlertTitle>{moment('2025-02-18T14:00:00').fromNow()}</AlertTitle>
            L'application vient de changer d'adresse: <a href="https://portail-armgp.enedis.fr">https://portail-armgp.enedis.fr</a> le "web-" disparait
            <br /><Button
              color="primary"
              size="small"
              onClick={handleDismissSnackbar}
            >
              Ne plus afficher ce message
            </Button>
          </Alert>
        </Snackbar>
      }
    </>
  );
};
