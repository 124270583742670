import PropTypes from 'prop-types'
import { useAuth } from '../../hooks/use-auth';


const ACL = ({ allowedRoles, allowedNni, forbidenRoles,forbidenNni, children, fallback }) => {

    const { user } = useAuth()
    const roles = user.userProfile
    // console.log('user', user.nni)

    // allowed Role or Allowed Nni 
    // and none of the forbiden ones
    const condition = (
        roles.some(role => allowedRoles.includes(role))
        || allowedNni?.includes(user.nni)
    )
    && !roles.some(role => forbidenRoles.includes(role))
    && !forbidenNni?.includes(user.nni)
        ;
    // console.log('acl condition : ', { condition, allowedRoles, forbidenRoles, roles });
    if (condition && children) return children
    else if (fallback) return fallback
    else return null
}

ACL.propTypes = {
    allowedRoles: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired
}

ACL.defaultProps = {
    allowedRoles: [],
    allowedNni: [],
    forbidenRoles: [],
    forbidenNni:[]
};

export default ACL