import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SplashScreen } from './components/splash-screen';
import { SettingsConsumer, SettingsProvider } from './contexts/settingsContext';
import { AuthConsumer, AuthProvider } from './contexts/GardianWebSSOAuthContext';
import { ImportStatusProvider } from './contexts/importStatusContext';
import { createTheme } from './theme';
import { createEmotionCache } from './utils/create-emotion-cache';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

const clientSideEmotionCache = createEmotionCache();

const App = (props) => {
  const content = useRoutes(routes);
  const { emotionCache = clientSideEmotionCache } = props;

  // make sure no one is here by mistake
  useEffect(() => {
    if (window.location.href.includes('web-'))
      if (!Cookies.get('dev')) {
        window.location.href = window.location.href.replace('web-', '');
      }
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider >
        <Helmet>
          <title>
            Portail-ARMGP
          </title>
          <meta
            name="viewport"
            content="initial-scale=1, width=device-width"
          />
        </Helmet>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthProvider>
            <ImportStatusProvider>
              <SettingsProvider>
                <SettingsConsumer>
                  {({ settings }) => (
                    <ThemeProvider theme={createTheme({ mode: settings.theme })}>
                      <CssBaseline />
                      <Toaster position="top-center" />
                      <AuthConsumer>
                        {(auth) => !auth.isInitialized
                          ? <SplashScreen />
                          : content}
                      </AuthConsumer>
                    </ThemeProvider>
                  )}
                </SettingsConsumer>
              </SettingsProvider>
            </ImportStatusProvider>
          </AuthProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
};

export default App;
