import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        minHeight: '100%',

      }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <CircularProgress />
          <br />
          <br />
          Chargement
        </Grid>
      </Grid>

    </Box>
  );
};

export default LoadingScreen;
