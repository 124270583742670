import PropTypes from 'prop-types';
import { List, ListSubheader } from '@mui/material';
import { DashboardSidebarItem } from './sidebarItem';
import ACL from '../authentication/ACL';

// render items 
const renderNavItems = ({ depth = 0, items, path }) => (
  <List disablePadding>
    {items.reduce((acc, item) => reduceChildRoutes({
      acc,
      item,
      depth,
      path,
    }), [])}
  </List>
);

const reduceChildRoutes = ({ acc, item, depth, path }) => {
  const key = `${item.title}-${depth}`;
  const partialMatch = path.includes(item.path);
  const exactMatch = path === item.path;

  if (item.children) {
    // console.log('item on if', { item })
    // if has childs
    acc.push(
      <ACL
        key={key}
        allowedRoles={item.allowedRoles}
      >
        <DashboardSidebarItem
          active={partialMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
          sx={item?.sx}
          colors={item?.colors}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            path
          })}
        </DashboardSidebarItem>
      </ACL>

    );
  } else {
    // console.log('item on else', { item })
    acc.push(
      <ACL
        key={key}
        allowedRoles={item.allowedRoles}
      >
        <DashboardSidebarItem
          active={exactMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          onClick={item.onClick}
          title={item.title}
          sx={item?.sx}
        />
      </ACL>

    );
  }

  return acc;
};

export const DashboardSidebarSection = (props) => {
  const { items, path, title, allowedRoles, ...other } = props;

  return (
    <ACL allowedRoles={allowedRoles}>
      {/* section */}
      <List
        subheader={(
          <ListSubheader
            // key={0}
            disableGutters
            disableSticky
            sx={{
              color: 'neutral.500',
              fontSize: '0.75rem',
              fontWeight: 700,
              lineHeight: 2.5,
              ml: 4,
              textTransform: 'uppercase',
              ...other?.sx  // title
            }}
          >
            {/* section title */}
            {title}
          </ListSubheader>
        )}
        {...other}>
        {/* items */}
        {renderNavItems({
          items,
          path,
        })}
      </List>
    </ACL>

  );
};

DashboardSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
