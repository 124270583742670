import { createContext, useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
const socketReconnectIntervalBase = 1000; // ms
const initialState = null;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATUS':
      return action.payload;
    default:
      return state;
  }
};

export const ImportStatusContext = createContext(initialState);

export const ImportStatusProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  // const { user } = useAuth();
  // const [devCookie, setDev] = useSessionStorage('dev', false);

  // get import evolution
  const getStateViaRoute = useCallback(async () => {
    const response = await axios.get('/api/import/');
    // console.log('getStateViaRoute', response);
    if (response?.status === 200) {
      const data = response.data;
      dispatch(data);
    }
  }, []);

  // on mount
  useEffect(() => {

    let serverEventSource;
    let reconnectInterval = socketReconnectIntervalBase;

    // open a EventSource to the server to get import evolution
    const connectToServerEvent = () => {
      serverEventSource = new EventSource('/api/import/workerSocket');
      serverEventSource.onmessage = function (event) {
        // debug
        const data = JSON.parse(event.data);
        console.log('data from import socket in provider', data);
        // setImportState(data)
        dispatch({ type: 'SET_STATUS', payload: data });
      };

      // debug
      serverEventSource.onopen = function (event) {
        console.log('Connection to server opened.');
        reconnectInterval = socketReconnectIntervalBase;
      };

      // auto reconnect
      serverEventSource.onerror = function (event) {
        console.log('workerSocket Error: ', event); //debug
        // Here's where we try to re-establish the connection in case it's closed
        if (serverEventSource.readyState === EventSource.CLOSED) {
          console.log('workerSocket Connection is closed. Attempting to reconnect... in ' + reconnectInterval + 'ms'); //debug 
          // Increase the interval each time we try to reconnect
          reconnectInterval = reconnectInterval * 1.5;
          // Wait for the specified interval, then re-establish the connection
          setTimeout(() => {
            console.log('workerSocket Reconnecting...'); // debug
            connectToServerEvent();  // recursively call connectToServerEvent function to set up a new connection
          }, reconnectInterval);
        }
      };
    };

    connectToServerEvent();
    getStateViaRoute(); // initial run
    // close this connection when user go to an other page
    return () => {
      if (serverEventSource) {
        console.log('workerSocket Closing the connection...'); //debug
        serverEventSource.close();
      }
    };

  }, [getStateViaRoute]);

  return (
    <ImportStatusContext.Provider
      value={{ state, dispatch }}
    >
      {children}
    </ImportStatusContext.Provider>
  );
};

ImportStatusProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const ImportStatusConsumer = ImportStatusContext.Consumer;
