import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';

export const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      auth.login();
    } else {
      setChecked(true);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.
  return <>{children}</>;

};

AuthGuard.propTypes = {
  children: PropTypes.node
};
