import { Button } from '@mui/material';
import { useAuth } from '../hooks/use-auth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';

export const UserInformationSnackbar = (props) => {
  // const { ...other } = props;

  const [displaySnackBar, setDisplaySnackBar] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    // Restore state from local/session storage
    if (user.snackbarDismiss === true) {
      setDisplaySnackBar(false);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const handleDismissSnackbar = async () => {
    if (displaySnackBar) {
      setDisplaySnackBar(false);
      try {
        await axios.post('/api/user/dismiss-snackbar');
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    displaySnackBar && <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={displaySnackBar}
      TransitionComponent={(TransitionProps) => (<Slide {...TransitionProps} direction="up" />)}
    >
      {/* <Alert severity="error" sx={{ width: "100%" }}> */}
      <Alert severity="info" sx={{ width: "100%" }}>
        {/* Il est désormais possible de combiner les filtres de deux manières ET ou OU, utilisez le selecteur pour essayer.<br/>
        Les petits mots de l'ancien pilot-armgp sont de retour, sur l'accueil.
        <br />le: 31/05 à 11:20 */}
        {/* Les clients récurrents sont retirés de la liste des appels post travaux. */}
        {/* L'import des données E-Plans est rétabli. */}
        L'import des données E-Plans est à nouveau fonctionel.
        <br />le: 06/11 8h
        <br /><Button
          color="primary"
          size="small"
          onClick={handleDismissSnackbar}
        >
          Ne plus afficher
        </Button>
      </Alert>
    </Snackbar>
  );
};
