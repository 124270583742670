import axios from 'axios';
import toast from 'react-hot-toast';
import { Button } from '@mui/material';
// import { useAuth } from '../hooks/use-auth';
axios.defaults.withCredentials = true // for debug stuff in backend

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.debug('before request', config);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {

        // const { logout, login } = useAuth();

        // const handleLogin = async () => {
        //     try {
        //         // onClose?.();
        //         await login();
        //     } catch (error) {
        //         console.error(error);
        //         toast.error('Impossible de se reconnecter');
        //     }
        // };

        switch (error.response?.status) {
            case 502:
                toast.error(
                    <div>Le serveur n'a pas répondu, essayez dans quelques secondes.
                        <Button
                            onClick={() => { window.location.reload() }}
                        >
                            Se reconnecter
                        </Button>
                    </div >
                );
                break;
            case 401:
                toast.error(
                    <div>Vous n'êtes pas connecté ou votre connexion à expiré.
                        <Button
                            onClick={() => { window.location.reload() }}
                        >
                            Se reconnecter
                        </Button>
                    </div >
                );
                break;
            case 403:
                toast.error(
                    <div>Vous n'êtes pas habilité à accèder à cette ressource.
                        <Button
                            onClick={() => { window.location.reload() }}
                        >
                            Se reconnecter
                        </Button>
                    </div >
                );
                break;
            case 404:
                toast.error('Cette ressource est introuvable.');
                break;
            default:
                toast.error('Erreur dans la requête;' + error.response?.status);
                console.error('axios error catcher', { status: error.response?.status, response: error.response })

                // let the error go further, for debug purpose
                // return Promise.reject(error); // we don't want it to go anywhere else :)
                break;
        }
    }
);

export default axios;