import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import { useLocation } from "react-router-dom";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isImpersonated: false,
  realUser: null,
  user: null,
  loggedUser: null,
};

const handlers = {
  IMPERSONATE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isImpersonated: true,
      isInitialized: true,
      user,
      loggedUser: user,
    };
  },
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isImpersonated: false,
      isInitialized: true,
      user,
      loggedUser: user,
      realUser: user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isImpersonated: false,
      user,
      loggedUser: user,
      realUser: user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isImpersonated: false,
    user: null,
    loggedUser: null,
    realUser: null,
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  platform: 'GardianWebSSO',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  impersonate: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  let location = useLocation()
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios('/api/user/me', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        // if we have user info from backend
        if (response?.status === 200) {
          const user = await response.data;
          // console.log('user from SSO :', { user }); //debug

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              isImpersonated: false,
              user,
              loggedUser: user,
            }
          });
        }
        else {
          // do a login
          await login();
          // clear user info from state
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              loggedUser: null,
            }
          });
        }
      } catch (err) {
        // do a login
        await login();
        // clear user info from state
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            loggedUser: null,
          }
        });
      }
    };

    initialize();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const login = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append('returnUrl', location.pathname);
    var url = new URL(window.location.origin + '/api/auth/login');
    url.search = searchParams.toString();
    window.location.assign(url.toString());
    return null;
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });
    window.location.assign('/api/auth/logout');
  };

  const impersonate = async (nni) => {
    let found = state.realUser.canImpersonate.find((u) => u.nni === nni)

    if (nni && found?.nni === nni) {
      // console.log('nni found :', nni, found);
      dispatch({
        type: 'IMPERSONATE',
        payload: {
          user: found,
          loggedUser: found,
        }
      });
    };
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        impersonate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
