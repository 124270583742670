
import moment from 'moment';
import localization from 'moment/locale/fr';
moment.updateLocale('fr', localization);

function addBusinessDays(originalDate, numDaysToAdd) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;

    const newDate = originalDate.clone();

    while (daysRemaining > 0) {
        newDate.add(1, 'days');
        if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
            daysRemaining--;
        }
    }

    return newDate;
}

function workdaysBetweenDates(startDate, endDate) {
    // + 1 cause diff returns the difference between two moments, in this case the day itself should be included.

    const totalDays = moment(endDate).diff(moment(startDate), 'days') + 1;
    const dayOfWeek = moment(startDate).isoWeekday();
    let totalWorkdays = 0;

    for (let i = dayOfWeek; i < totalDays + dayOfWeek; i++) {
        if (i % 7 !== 6 && i % 7 !== 0) {
            totalWorkdays++;
        }
    }
    return totalWorkdays;
}

// // https://stackoverflow.com/a/28425663
// function workday_count(start, end) {
//   var first = start.clone().endOf('week'); // end of first week
//   var last = end.clone().startOf('week'); // start of last week
//   var days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
//   var wfirst = first.day() - start.day(); // check first week
//   if (start.day() ===0) --wfirst; // -1 if start with sunday 
//   var wlast = end.day() - last.day(); // check last week
//   if (end.day() ===6) --wlast; // -1 if end with saturday
//   return wfirst + Math.floor(days) + wlast; // get the total
// } //              ^ EDIT: if days count less than 7 so no decimal point

// // https://stackoverflow.com/a/48938331
// Date.prototype.addDays = function (days) {
//   var date = new Date(this.valueOf());
//   date.setDate(date.getDate() + days);
//   return date;
// };

// function getNumWorkDays(startDate, endDate) {
//   var numWorkDays = 0;
//   var currentDate = new Date(startDate);
//   while (currentDate <= endDate) {
//     // Skips Sunday and Saturday
//     if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
//       numWorkDays++;
//     }
//     currentDate = currentDate.addDays(1);
//   }
//   return numWorkDays;
// }



export {
    addBusinessDays,
    workdaysBetweenDates,
    moment
};
