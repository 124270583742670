import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';

const ACL = ({ allowedRoles, allowedNni, forbidenRoles, forbidenNni, children, fallback }) => {
    const { user } = useAuth();
    const userRoles = user?.roles || [];
    const userNni = user?.nni || '';

    const safeAllowedRoles = Array.isArray(allowedRoles) ? allowedRoles : [];
    const safeAllowedNni = Array.isArray(allowedNni) ? allowedNni : [];
    const safeForbidenRoles = Array.isArray(forbidenRoles) ? forbidenRoles : [];
    const safeForbidenNni = Array.isArray(forbidenNni) ? forbidenNni : [];

    // Condition for access control
    // is user role in allowedRoles and not in forbidenRoles
    // or is user nni in allowedNni and not in forbidenNni
    const condition =
        (
            userRoles.some(role => safeAllowedRoles.includes(role))
            || safeAllowedNni.includes(userNni)
        )
        && !userRoles.some(role => safeForbidenRoles.includes(role))
        && !safeForbidenNni.includes(userNni);

    if (condition && children) return children;
    else if (fallback) return fallback;
    else return null;
};

ACL.propTypes = {
    allowedRoles: PropTypes.array,
    allowedNni: PropTypes.array,
    forbidenRoles: PropTypes.array,
    forbidenNni: PropTypes.array,
    children: PropTypes.node.isRequired,
    fallback: PropTypes.node,
};

ACL.defaultProps = {
    allowedRoles: [],
    allowedNni: [],
    forbidenRoles: [],
    forbidenNni: [],
    fallback: null,
};

export default ACL;