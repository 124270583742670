import { AppBar, Popover, Avatar, ButtonBase, ButtonGroup, IconButton, Toolbar, Box, Button, Typography, Modal } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useRef, useCallback, useEffect, useState, useContext } from 'react';
import { useAuth } from '../../hooks/use-auth';
import { Menu as MenuIcon } from '../../icons/menu';
import { stringAvatar } from '../../utils/avatar';
import { AccountPopover } from './sidebarAccount';
import { ImportStatusContext } from '../../contexts/importStatusContext';
import { moment } from '../../utils/date';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import cronstrue from 'cronstrue/i18n';
import { useNavigate } from 'react-router-dom';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import axios from 'axios';


const ImportStatus = ({ label, allValues, values, sublabel }) => {
  const latestCount = values?.count;
  const latestDate = values?.date;
  const isActive = allValues?.lastCommunicationAt !== undefined && moment(allValues?.lastCommunicationAt).isAfter(moment().subtract(5, 'minutes'));

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        // color="secondary"
        sx={{
          fontSize: '12px',
          color: theme => theme.palette.grey[600],
        }}
      >
        {`${label}, `}
        {latestCount === undefined ? '' : `${latestCount} ${sublabel} ${moment(latestDate).fromNow()}`}
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: 'none' }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            Executions plannifiés {allValues?.cron && cronstrue.toString(allValues?.cron, { locale: "fr" })}
          </Typography>
        </Popover>
        {(allValues?.state === 'RUNNING' || isActive) && <FileDownloadIcon sx={{ heigth: '12px', width: '12px', animation: `${blinking} 1.5s infinite`, verticalAlign: 'middle', marginLeft: '5px' }} />}
        <br />
      </Typography>

    </>
  );
};

ImportStatus.propTypes = {
  taskName: PropTypes.string,
  allValues: PropTypes.object,
  taskData: PropTypes.object,
  sublabel: PropTypes.string
};

const blinking = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
      boxShadow: theme.shadows[3]
    }
    : {
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      boxShadow: 'none'
    })
}));

const AccountButton = () => {
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  // To get the user from the authContext, you can use
  const { user, isImpersonated } = useAuth();

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2
        }}
      >
        <Avatar
          {...stringAvatar(user.fullName)}
          sx={{
            ...(isImpersonated && {
              border: '2px solid red'
            })
          }}
        />
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export const DashboardNavbar = (props) => {
  const { onOpenSidebar, ...other } = props;
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [quote, setQuote] = useState({ text: "", id: null });
  const [openModal, setOpenModal] = useState(false); // État pour gérer l'ouverture du Modal

  const getQuote = useCallback(async () => {
    try {
      let quote = await axios.get('/api/quote/one');
      quote = quote.data.data;
      setQuote(quote);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const likeQuote = async (id) => {
    try {
      await axios.post('/api/quote/like', { id: id });
      getQuote(); // Rafraîchit la citation après le vote
    } catch (error) {
      console.error(error);
    }
  };

  const disLikeQuote = async (id) => {
    try {
      await axios.post('/api/quote/disLike', { id: id });
      getQuote(); // Rafraîchit la citation après le vote
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getQuote();
  }, [getQuote, user.bannerDismiss]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Gestion du Modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { state: importStatus } = useContext(ImportStatusContext);

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            xl: 280
          },
          width: {
            xl: 'calc(100% - 280px)'
          }
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onOpenSidebar}
            title='Sidebar toggle'
            sx={{
              display: {
                xs: 'inline-flex',
                xl: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
            <Box
              component="span"
              sx={{
                fontWeight: 'bold',
                fontSize: '16px',
                verticalAlign: 'middle',
              }}
            >
              MENU {window.location.href.includes('web-') && '(DEV-v22)'}
            </Box>
          </IconButton>
          <Box
            sx={{
              mr: '15px',
              ml: '15px',
              minWidth: '175px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              flexWrap: 'nowrap',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <Avatar
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              children={importStatus?.workers?.length}
              onClick={() => user?.roles?.includes('ADMIN') && navigate('/imports')}
              sx={{
                bgcolor: importStatus?.workers?.length > 0 ? 'darkgrey' : 'darkred',
                width: 23,
                height: 23,
                flexShrink: 0,
              }}
            />
            <Typography
              component="span"
              sx={{
                whiteSpace: 'nowrap',
                color: theme => theme.palette.grey[600],
              }}
            >
              robot(s) connecté(s)
            </Typography>

            <Popover
              id="mouse-over-popover"
              sx={{ pointerEvents: 'none' }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {
                (user?.roles?.includes('ADMIN') && importStatus?.workers?.length > 0) ?
                  importStatus?.workers?.map((worker, index) => (
                    <Typography key={index} sx={{ p: 1 }}>
                      {worker.agentName.toUpperCase()} ({worker.version})
                    </Typography>
                  ))
                  : `${importStatus?.workers?.length || 0} robot(s) connecté(s)`
              }
            </Popover>
          </Box>

          {/* ETAT DES IMPORTS : */}
          <Box sx={{ mr: '15px', ml: '15px', minWidth: '220px' }} >
            <ImportStatus
              label="EPLAN"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.eplan}
              values={importStatus?.tasks?.eplan?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "prestation")?.updatedAt?.latest}
            />
            <ImportStatus
              label="EPLAN"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.eplan}
              values={importStatus?.tasks?.eplan?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "attachement")?.updatedAt?.latest}
            />
            <ImportStatus
              label="EPLAN"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.eplan}
              values={importStatus?.tasks?.eplan?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "pointarret")?.updatedAt?.latest}
            />
            <ImportStatus
              label="WFM"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.wfm}
              values={importStatus?.tasks?.wfm?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "taches")?.updatedAt?.latest}
            />
            <ImportStatus
              label="OSR"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.osr}
              values={importStatus?.tasks?.osr?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "osr")?.updatedAt?.latest}
            />
          </Box>
          <Box sx={{ mr: '15px', ml: '15px', minWidth: '260px' }} >
            <ImportStatus
              label="CUAU"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.cuau}
              values={importStatus?.tasks?.cuau?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "cuau")?.updatedAt?.latest}
            />
            <ImportStatus
              label="CUAU"
              sublabel="ajout"
              allValues={importStatus?.tasks?.cuau}
              values={importStatus?.tasks?.cuau?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "cuau")?.createdAt?.latest}
            />
            <ImportStatus
              label="PRAC"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.prac}
              values={importStatus?.tasks?.prac?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "taches")?.updatedAt?.latest}
            />
            <ImportStatus
              label="Smart-A invit"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.smartAgenda}
              values={importStatus?.tasks?.smartAgenda?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "rdv pris sur invitation")?.updatedAt?.latest}
            />
            <ImportStatus
              label="Smart-A Agent"
              sublabel="mis à jour"
              allValues={importStatus?.tasks?.smartAgenda}
              values={importStatus?.tasks?.smartAgenda?.modelLastChanges?.find(e => e['modelName'].toLowerCase() === "rdv par agent")?.updatedAt?.latest}
            />
          </Box>

          {/* quote */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexGrow: 1,
              gap: '8px'
            }}
          >
            <Typography
              variant="h6"
              color={theme => theme.palette.grey[600]}
              sx={{
                fontSize: '14px',
                flex: 1,
                wordWrap: 'break-word',
                mt: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                cursor: 'pointer'
              }}
              onClick={handleOpenModal}
            >
              {quote?.text}
            </Typography>
          </Box>

          {/* Modal pour afficher la citation en grand */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="quote-modal-title"
            aria-describedby="quote-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: '600px',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}
            >
              <Typography
                id="quote-modal-title"
                variant="h4"
                component="h2"
              >
                Citation
              </Typography>
              <Typography
                id="quote-modal-description"
                sx={{
                  fontSize: '20px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {quote?.text}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <ButtonGroup size="small">
                  <Button
                    onClick={() => likeQuote(quote.id)}
                    startIcon={<ThumbUpIcon fontSize="small" />}
                  >
                    J'aime
                  </Button>
                  <Button
                    onClick={() => disLikeQuote(quote.id)}
                    startIcon={<ThumbDownIcon fontSize="small" />}
                  >
                    J'aime pas
                  </Button>
                </ButtonGroup>
                <Button
                  size="small"
                  href="https://teams.microsoft.com/l/chat/0/0?users=mathieu-m.fernandes%40enedis.fr"
                  sx={{
                    minWidth: 'auto',
                    padding: '2px 8px',
                    fontSize: '12px',
                    color: theme => theme.palette.grey[600],
                  }}
                >
                  Proposez la vôtre
                </Button>
                <Button
                  onClick={handleCloseModal}
                  variant="outlined"
                >
                  Fermer
                </Button>
              </Box>
            </Box>
          </Modal>

          <AccountButton />
        </Toolbar>
      </DashboardNavbarRoot >
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};