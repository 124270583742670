import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import { useLocation } from "react-router-dom";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  platform: 'GardianWebSSO',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  let location = useLocation()
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios('/api/user/me', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (response?.status === 200) {
          const user = await response.data;
          // console.log('user from SSO :', user)
          
          // add a simplified view of user roles
          user.userProfileSimple =
            user.userProfile
              .filter(role => role.startsWith('6GF'))
              .map(role => (role.replaceAll('6GF_', '')))

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        }
        else {
          await login();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        await login();
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const login = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append('returnUrl', location.pathname);
    var url = new URL(window.location.origin + '/api/auth/login');
    url.search = searchParams.toString();
    window.location.assign(url.toString());
    return null;
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });
    window.location.assign('/api/auth/logout');
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
