import { Box } from '@mui/material';
import { Logo } from './logo';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const SplashScreen = styled((props) => {
  // const { variant, ...other } = props;
  // const color = variant === 'light' ? '#C1C4D6' : '#5048E5';

  return (
    <Box
      sx={{
        alignItems: 'center',
        // backgroundColor: 'neutral.900',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 2000
      }}
    >
      <Logo
        sx={{
          height: 80,
          width: 80
        }}
      />
    </Box>
  )
})``;

SplashScreen.defaultProps = {
  variant: 'light'
};

SplashScreen.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
};
